import React from "react";

import Layout from "../components/layout";
import Hero from "../components/hero";
import SEO from "../components/seo";
import Showcase from "../components/showcase";
import About from "../components/about";
import Contact from "../components/contact";
import Footer from "../components/footer";
import ReactPlayer from "react-player";

function AppreciableSetup() {
  return (
    <Layout>
      <SEO
        title="Boost sales and grow your business"
        keywords={[`shopify`, `e-commerce`, `shop`, `sales`]}
      />
      <div className="flex flex-col my-24 items-center">
        <h1 className="my-4 text-2xl font-medium">Setting up Appreciable</h1>
        <div className="w-2/5 h-96">
          <ReactPlayer
            url="https://youtu.be/OXzg6w8B7bI"
            width='100%'
            height='100%'
          />
        </div>
      </div>
      <Footer />
    </Layout>
  );
}

export default AppreciableSetup;
